@import '../../../styles/utils/colors';

@import '../utils/mixins';


.menu {
    @include flex($alignX:space-between, $wrap:nowrap);
    position: relative;

    [class*="iconBtn"]{
        z-index: 120;
        position: relative;
    }

    :global(.menu-open) & {
        color: #fff;
        svg {
            fill: #fff !important;
        }

        a {
            @include nthTransition($animationDelay:.025s);
            transform: none;
            opacity: 1;
        }
        @include mqFrom($portrait){
            // color: initial;
            // fill: initial;
        }
    }


    a {
        padding: var(--paddingMini) var(--paddingSmall);
        // background-color: var(--secondary);
        color: inherit;
        font-size: 1.2rem;
        z-index: 100;
        text-transform: uppercase;
        transform: translateY(15px);
        opacity: 0;
        
        &:hover, &:global(.active), &:global(.link-active) {
            color: $complement;
        }

        @include mqFrom($portrait){
            transform: none;
            opacity: 1;
            transition: all .2s ease;
        }
    }

    &Nav {
        @include flex($dir: column, $align: flex-start);
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        top: 0px;
        opacity: 0;
        pointer-events: none;
        transition: var(--bezier);
        background-color:$primary;
        height: 100vh;
        z-index: 150;
        padding: {
            top: 150px;
            bottom: var(--paddingLarge);
        }

        :global(.menu-open) & {

            pointer-events: initial;
            opacity: 1;
            transform: none;
        }

        @include mqFrom($portrait){
            pointer-events: initial;
            background: none;
            padding:0;
            position: relative;
            opacity: 1;
            height: auto;
            transform: none;
            @include flex($alignX:space-between);
            top: auto;
            margin-right: 75px;
        }
        
        @include mqFrom($desktop){
            margin-right: 0px;
        }
    }

    &Main {
        @include flex($dir: column, $alignX:flex-start);
        width: 100%;
        margin-bottom: var(--paddingMedium);


        @include mqTo($portrait - 1){
            a {
                font-size: 2.1rem;
            }
        }

        @include mqFrom($portrait){
            @include flex($alignX:space-between);
            width: auto;
            margin: auto;

            a {
                &:last-child {
                    margin-right: var(--paddingSmall);
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }

    }

    &Sub {
        @include flex( $alignX:space-between);
        width: 100%;

        a {
            width: 50%;
        }

        // img {
        //     display: none;
        // }
        
        :global(.menu-open) & {
            pointer-events: initial;
            opacity: 1;
            transform: none;
            transition-delay: .05s;
            filter: none;
        }

        @include mqFrom($portrait){
            @include flex($dir:column);
            width: auto;
            transform: translateY(40px) scale(1.25);
            opacity: 0;
            pointer-events: none;
            transition: var(--bezier);
            position: absolute;
            top:var(--paddingLarge);
            // top: 0;
            right: 0;
            left: 0;
            background: black;
            color: #fff;
            // width: 75vw;
            // width: 400px;
            min-height: 5vw;
            padding: var(--paddingMedium) var(--paddingLarge);
            // @include paddingXFluid(50px, 140px);
            padding: 5vw 9vw;

            width: 100vw;
            height: 60vw;
            max-height: 100vh;
            // margin-right: -130%;
            @include flex($dir:column, $alignX: flex-end);
            z-index: 80;
            // z-index: -1;
            position: fixed;
            top:0;
            // filter: blur(100x);

            // img {
            //     display: block;
            //     position: absolute;
            //     left: 0;
            //     margin: var(--paddingLarge);
            // }

            a {
                width: auto;

                // @include title_medium;
            }
        }
    }

    
}

.footer {
    margin: var(--paddingMedium) 0 0;
    @include flex($dir:column, $align:flex-start);

    a {
        margin: 3px 0;

         &:hover {
            color: $complement;
        }
    }
    @include mqFrom($portrait){
        margin: var(--paddingMedium) 0;

        margin-left: auto;
        @include flex( $align:flex-end);

        a {
            margin: 0 10px;
        }

        :global(.menu-open) & {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 100;
            text-align: right;
            padding: 5vw 9vw;
            // @include paddingFluid(30px, 140px);
        }
    }

}