

@import './styles/utils/_variables';
@import './styles/utils/_colors';
@import './src/components/ui/utils/mixins';
@import './styles/utils/_typography';
@import './styles/utils/_base';
@import './styles/utils/_images';

@import "~react-datepicker/dist/react-datepicker.css";


body {
  width: 100%;
  // background: url(./media/paper.jpg) no-repeat;
  background-color: $gray;
  color: $primary;
  // background-color: $gray;
  background-size: cover;

  // background-color: #333;
  // background-blend-mode: color-burn;
}

.hidden {
  display: none !important;
}