@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Open+Sans:wght@400;600;700&display=swap');

:root {
    --fontNormal: 'Nunito', 'Ubuntu', sans-serif;
    --fontNormal: 'Open sans', 'Ubuntu', sans-serif;
    --fontTitle: 'Nunito', 'Ubuntu', sans-serif;
    --fontTitle: 'Abril Fatface', 'Ubuntu', sans-serif;

    --fontSize: .9rem;
    --fontSizeNormal: 1rem;
    --fontSizeMedium: 1.3rem;
    --fontSizeMediumTitle: 2rem;
    --fontSizeLarge: 1.7rem;
    --fontSizeLargest: 2rem;

    @include mqFrom($portrait){
    --fontSize: 1rem;
    --fontSizeNormal: 1rem;
    --fontSizeMedium: 1.3rem;
    --fontSizeMediumTitle: 3rem;
    --fontSizeLarge: 4rem;
    --fontSizeLargest: 5rem;
    }
}

@mixin font($ff:var(--fontNormal), $fw:300, $lh:1.4){
    font-family: $ff;
    font-weight: $fw;
    line-height: $lh;
}

@mixin fontNormal($fw:300, $lh:1.4){
    @include font(var(--fontNormal), $fw, $lh:1.4)
}

@mixin fontTitle($fw:700, $lh:1){
    @include font(var(--fontTitle), $fw, $lh:$lh)
}

body {
    @include fontNormal();
}

h1,h2 {
    @include fontTitle($lh:1.1, $fw:400);
    font-size: var(--fontSizeLargest);
}

h1 {
    font-size: var(--fontSizeLargest);
}
h2 {
    font-size: var(--fontSizeLarge);
}
h3, h4, h5 {
    @include fontNormal($lh:1.1, $fw:700);
    font-size: var(--fontSizeNormal);
    margin-bottom: var(--paddingMini);
}

a, b {
    @include fontNormal($lh:1.1, $fw:700);

    text-decoration: none;
}

input {
    @include fontNormal();
}

[class*="button"]{
    // @include fontTitle();
            @include fontNormal($lh:1.1, $fw:700);

}

.title {
    &--largest {
        font-size: var(--fontSizeLargest);
        max-width: 600px;
    }
    &--large {
        font-size: var(--fontSizeLarge);
    }
    &--medium {
        font-size: var(--fontSizeMediumTitle);
    }
    &--normal {
        font-size: var(--fontSizeNormal);
    }
}

.left {
    text-align: left;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}

.markdown {
    white-space: pre-wrap;
}