
img {
    vertical-align: bottom;
    max-width: 100%;
}

.circle {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    object-fit: cover;
}

.images {
    
    margin-bottom: var(--paddingLarge);
    @include paddingYFluid;
    padding-top: 0;

    figure {
        @include flex;
        margin: 0;
        overflow: hidden;
        margin-bottom: var(--paddingMedium);
    }

    @include mqFrom ($portrait){
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: var(--paddingMedium);
        grid-row-gap: var(--paddingMedium);
    

        figure {
            margin: 0;

            &:nth-of-type(1) { grid-area: 1 / 1 / 5 / 3; }
            &:nth-of-type(2) { grid-area: 1 / 3 / 3 / 5; }
            &:nth-of-type(3) { grid-area: 3 / 3 / 5 / 5; }
        }
    }

    img {
        margin: 0;
        max-width: none;
        height: 100%;
        object-fit: cover;
    }

}

