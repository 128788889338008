@import '../components/ui/utils/mixins';
@import '../styles/utils/colors';

@keyframes spin {
    from {
        transform: rotate(0deg);
        border:4px solid $primary;
        border-left: 4px solid transparent;
    }
    50% {
        border:4px solid $primary;
        border-left: 4px solid transparent;
    }
    to {
        transform: rotate(360deg);
        border:4px solid $primary;
        border-left: 4px solid transparent;
    }
}

.products {
    position: relative;
    min-height: 300px;

    &:after {
        pointer-events: none;
        content: "";
        @include absFill;
        // background-color: green;
        z-index: 1;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border:4px solid #000;
        border-left: 4px solid transparent;
        display: block;
        position: absolute;
        top: 45%;
        left:0;
        right: 0;
        margin: auto;
        animation: spin 1.1s infinite linear;
        transition: all .3s ease .15s;
        opacity: 0;

    }

    &--loading {
        @extend .products;
        // background-color: red;

        &:after {
            opacity: 1;
        }

    }
}

.default {
    // background-color: red;
    min-height: 50vh;
    transition: all .2s ease;
    backface-visibility: hidden;
    opacity: 0;
    transform: scale(1.025);
    transform: scale(.98) translateY(10px);
    transform: scale(1.02) translateY(10px);
    // padding: var(--paddingMedium) 0;

    &--loaded {
        @extend .default;
        // background-color: green;
        // transform: scale(1.05);
        transition: var(--bezier);
        transform: none;
        opacity: 1;
    }
}

.map {
    max-height: 400px;
    object-fit: cover;
    width: 100%;
}