@import '../../../styles/utils/variables';

@mixin flex($display: flex, $dir: row, $align: center, $alignX: $align, $alignY: $align, $wrap:wrap) {
  display: $display;
  flex-wrap: $wrap;
  flex-direction: $dir;

  @if $dir == "row" {
    align-items: $alignY;
    align-content: $alignY;
    justify-content: $alignX;
  } @else {
    justify-content: $alignY;
    align-items: $alignX;
    align-content: $alignX;
  }
}

@mixin mqFrom($media: $landscape) {
  @media screen and (min-width: #{$media}) { @content; }
}
@mixin mqTo($media: $landscape) {
  @media screen and (max-width: #{$media}) { @content; }
}

@mixin class($className) {
  .#{$className}, [class*="#{$className}"] { @content; }
}


@mixin absFill($top:0,$right:0,$bottom:0,$left:0) {
  position: absolute;
  top:$top;
  bottom:$bottom;
  right:$right;
  left:$left;
}

@mixin preventTouchDelay(){
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none;
}

@mixin ul-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

//  @include aspect-ratio(16, 9);
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@mixin triangle($size:10px, $color: $primary){
  width: 0; 
  height: 0; 
  border-left: $size/1.5 solid transparent;
  border-right: $size/1.5 solid transparent;
  border-bottom: $size solid $color;
}

@mixin nthTransition($animationDelay: 0.15s, $nthDelay: .05s, $elements: 20){
  @for $i from 0 to $elements+1 {
      &:nth-child(#{$i}) {
        $newDelay: $nthDelay* $i + $animationDelay;
        transition: opacity .6s var(--cubicBezier) $newDelay*1.5, transform .9s var(--cubicBezier) $newDelay;
      }
  }
}

@mixin flowX($dist:$gutter){
    width: calc(100% + (#{$dist} * 2));
    max-width: calc(100% + (#{$dist} * 2));
    margin: {
        left: -$dist;
        right: -$dist;
    }
}

@mixin fontFluid($font-size-min: 1rem, $font-size-max: 1.3rem, $lower-range: 450px, $upper-range: 1400px) {
  font-size: calc(#{$font-size-min} + #{(($font-size-max / ($font-size-max * 0 + 1)) - ($font-size-min / ($font-size-min * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  
  @media screen and (max-width: $lower-range) {
    font-size: $font-size-min;
  }
  @media screen and (min-width: $upper-range){
    font-size: $font-size-max;
  }
}

$breakMax: 1800px;


@mixin paddingFluid($padding-min: 1rem, $padding-max: 3rem, $lower-range: 450px, $upper-range: $breakMax) {
  padding: calc(#{$padding-min} + #{(($padding-max / ($padding-max * 0 + 1)) - ($padding-min / ($padding-min * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  
  @media screen and (max-width: $lower-range) {
    padding: $padding-min;
  }
  @media screen and (min-width: $upper-range){
    padding: $padding-max;
  }
}

@mixin paddingYFluid($padding-min: 16px, $padding-max: 50px, $lower-range: 450px, $upper-range: $breakMax) {
  padding-top: calc(#{$padding-min} + #{(($padding-max / ($padding-max * 0 + 1)) - ($padding-min / ($padding-min * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  padding-bottom: calc(#{$padding-min} + #{(($padding-max / ($padding-max * 0 + 1)) - ($padding-min / ($padding-min * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  
  @media screen and (max-width: $lower-range) {
    padding-top: $padding-min;
    padding-bottom: $padding-min;
  }
  @media screen and (min-width: $upper-range){
    padding-top: $padding-max;
    padding-bottom: $padding-max;
  }
}

@mixin paddingXFluid($padding-min: 1rem, $padding-max: 3rem, $lower-range: 450px, $upper-range: $breakMax) {
  padding-left: calc(#{$padding-min} + #{(($padding-max / ($padding-max * 0 + 1)) - ($padding-min / ($padding-min * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  padding-right: calc(#{$padding-min} + #{(($padding-max / ($padding-max * 0 + 1)) - ($padding-min / ($padding-min * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  
  @media screen and (max-width: $lower-range) {
    padding-left: $padding-min;
    padding-right: $padding-min;
  }
  @media screen and (min-width: $upper-range){
    padding-left: $padding-max;
    padding-right: $padding-max;
  }
}