/* CSS HEX */
:root {
--jet: #353535ff;
--white: #ffffffff;
--light-gray: #d2d7dfff;
--pale-silver: #bdbbb0ff;
--middle-grey: #8a897cff;

--red: rgb(188, 88, 88);
--yellow: #ffeca9;
--green: #719059;
--beige: #d9c9ad;

--primary: var(--jet);
--secondary: var(--middle-grey);
--complement: #048BA8;
--btnHover: rgb(66, 65, 65);
--borderColor: #e3e3e3;
}

$red:rgb(188, 88, 88);

$primary: #294259;
$secondary: #b5ccd9;
$gray: #d4dee0;
$beige: #d9c9ad;
$complement: #f28c9e;

/* SCSS HEX */
$jet: #353535ff;
$white: #ffffffff;
$light-gray: #d2d7dfff;
$pale-silver: #bdbbb0ff;
$middle-grey: #8a897cff;

/* SCSS HSL */
$jet: hsla(0, 0%, 21%, 1);
$white: hsla(0, 0%, 100%, 1);
$light-gray: hsla(217, 17%, 85%, 1);
$pale-silver: hsla(51, 9%, 72%, 1);
$middle-grey: hsla(56, 6%, 51%, 1);

/* SCSS RGB */
$jet: rgba(53, 53, 53, 1);
$white: rgba(255, 255, 255, 1);
$light-gray: rgba(210, 215, 223, 1);
$pale-silver: rgba(189, 187, 176, 1);
$middle-grey: rgba(138, 137, 124, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-right: linear-gradient(90deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-bottom: linear-gradient(180deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-left: linear-gradient(270deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-top-right: linear-gradient(45deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-bottom-right: linear-gradient(135deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-top-left: linear-gradient(225deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-bottom-left: linear-gradient(315deg, #353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);
$gradient-radial: radial-gradient(#353535ff, #ffffffff, #d2d7dfff, #bdbbb0ff, #8a897cff);

$allPartners: (
    ("primary", $primary, #fff),
    ("primary-light", rgba($beige, .2), #000),
    ("secondary", $secondary, inherit),

);

// Generate Button Modifiers
@each $p in $allPartners {
  .bg--#{nth($p,1)}{
    background-color: #{nth($p,2)};
    color: #{nth($p,3)};
  }
}

input {
    color: $primary;
    border-color: $primary;
}
