@import '../../../styles/utils/colors';

@import '../utils/mixins.scss';

.main {
    width:100%;
}

.header {
    @include paddingFluid(25px, 30px);
}

.headerLogo {
    z-index: 999999;
    position: relative;

    img, svg {
        object-fit: contain;
        width: 75px;
        height: 75px;
        transition: var(--bezier);

        + img, + svg {
            display: none;
        }

        // @include mqTo($portrait - 1){
            
            :global(.menu-open) &, :global(.page-start) & {
                color: #fff;
                fill: #fff;
            }
            :global(.menu-open) & {
                fill: #fff !important;
            }
        // }

        @include mqFrom($portrait){
            width: auto;
            height: 125px;

        }
    }
}


.iconBtn {
    cursor: pointer;
    z-index: 999999;
    position: relative;

    @include mqFrom($portrait){
        display: none;
    }
}

.topHeader {
    // @include paddingFluid(25px, 50px);
    // background: inherit;
    position: sticky;
    top: 0;
    width: 100%;
    top: 0;
    left:0;
    right:0;
    z-index: 10;
    background: $white;
    // background: $white--warm;

    color: $primary;

    :global(svg) {
        fill: $primary;
    }

    :global(.menu-open) & :global(svg) {
        fill: #fff !important;
    }

    @include mqFrom($portrait){
        background: $gray;
        // position: relative;
        padding: var(--paddingMedium) 0;

        &:not([class*="bg"]){
            background: none;
            background: $gray;

        }
    }

    img, svg {
        margin: 0;
    }

    .logo {
        background:red;
    }

    &Content {
        @include flex($alignX:space-between);
        // background-color: green;
    }
}

.sticky {
    transform: none;
    will-change: transform;
    transition: transform .1s linear;
    // position: fixed;


    &--hide {
        @extend .sticky;
        transform: translateY(-100%);
    }
    &--minimize {
        @extend .sticky;
        // transform: translateY(-50%);

        padding: {
            top: var(--paddingMini);
            bottom: var(--paddingMini);
        }

        img, svg {
            // &:only-child {
                height: 30px;
            // }
            display: none;
            + img, + svg {
                display: block;
            }
        }
    }
}

.banner {
    @include flex($dir:column);
    position: relative;
    // background: $primary;
    // max-width: 1500px;
    margin: {
        left: auto;
        right: auto;
    }

    &Image {
        @include flex($dir:column);
        position: relative;
        width: 100%;
        color: #fff;

        img {
            width: 100%;
            max-height: 75vh;
            object-fit: cover;
            vertical-align: bottom;
            margin: 0;
            pointer-events: none;
        }
    }

    &Content {
        @include paddingYFluid(50px, 100px);
        @include flex($dir:column);
        // position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // background: radial-gradient(circle, rgba($primary,0.35) 0%, rgba(255,255,255,0) 100%);
        font-size: var(--fontSizeMedium);
        // font-weight: 600;
    }

    &Title {
        position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        max-width: 700px;
        text-align: center;
    }
}

.container {
    width: 100%;
    max-width: 90%;
    margin: {
        left: auto;
        right: auto;
    }

    @include mqFrom($desktop){
        max-width: 1000px;
    }

}

.inner {
  @include paddingFluid(20px, 40px);

  &--x {
    @include paddingXFluid(20px, 40px);
  }

  &--y {
    @include paddingYFluid(20px, 40px);
  }
}


.padding-fluid {
    @include paddingFluid(25px, 50px);
}

.box {
    padding: var(--paddingLarge);
    max-width: 90%;
    @include flex;
    text-align: center;
    margin: {
        left: auto;
        right: auto;
    }

    &--small {
        padding: var(--paddingSmall);
    }
    &--medium {
        padding: var(--paddingMedium);
    }
}

$columnsClassTotal:8;

.columns {
    @include flex();
    margin-bottom: var(--paddingMedium);
    width: 100%;


    @for $i from 1 through $columnsClassTotal {
        &--#{$i} {
            @extend .columns;

            [class*="column"]{
                @include mqFrom(768px){
                    width: 100% / $i;
                }
            }

            &[class*="space"]{
                [class*="column"]{
                    margin: calc(var(--paddingMedium) / 2);

                    @include mqFrom(768px){
                        width: calc(100% / #{$i} - var(--paddingMedium));

                    }
                }
            }
        }
    }

    &--space {
        width: auto;

        padding: {
            top: calc(var(--paddingMedium) / 2);
            bottom: calc(var(--paddingMedium) / 2);
        }
        @include mqFrom($portrait){

            margin: {
                // top: calc(var(--paddingMedium) / 2);
                // bottom: calc(var(--paddingMedium) / 2);
                left: calc(var(--paddingMedium) / -2);
                right: calc(var(--paddingMedium) / -2);
            }
        }
        
    }

}

.footer {
    @include paddingFluid(25px, 50px);
    background-color: $primary;
    color: #fff;

    a {
        color: inherit;
        margin-right: 1rem;
        font-weight: inherit;

        svg {
            margin-right: .5rem;
        }
    }

    svg path {
        fill: #fff ;
    }

    img {
        margin: 0;
    }

    [class*="columns"]{
        flex: 1;
        align-content: flex-start;
        align-items: flex-start;
    }

    // @include mqFrom($portrait){
    //     [class*="column"]{
    //         width: calc(25% - var(--paddingMedium));
    //     }
    // }

    &Content {
        @include flex($dir: column, $align:flex-start);
        position: relative;

        @include mqFrom($portrait){
            @include flex($alignX:space-between, $alignY:flex-start);
        }

        [class*="columns"]{
            flex: 1;
            align-content: flex-start;
            align-items: flex-start;

            

        }

        // background-color: green;
    }

    // nav {
    //     margin-left: auto;
    // }
}

.heading {
    max-width: 750px;
    text-align: center;
    margin: {
        left: auto;
        right: auto;
        bottom: var(--paddingMedium);
    }
}


.column {
    // @include nthTransition;
    color: inherit;
    width: 100%;
    min-width: 250px;

    &Fill {
        flex: 1;
    }

    @for $i from 1 through $columnsClassTotal {
        &--#{$i} {
            // @extend .column;
            @include mqFrom(768px){
                width: 100% / $i;
            }
        }
    }

}

.stretch {
    justify-content: stretch;
    align-items: stretch;
}

.fill {
}
