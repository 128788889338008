@import './helpers';

/* Global settings */
$default-size: 24;
$default-stroke-width: 2;
$gutter: var(--padding);

@function str-replace($string, $search, $replace: '') {
  $string: inspect($string);
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function url-encode($string) {
  $map: (
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    " ": "%20",
    "!": "%21",
    "*": "%2A",
    "'": "%27",
    '"': "",//NOTE: Remove this rather then encode it. Use '' inside strings.
    "(": "%28",
    ")": "%29",
    ";": "%3B",
    ":": "%3A",
    "@": "%40",
    "&": "%26",
    "=": "%3D",
    "+": "%2B",
    "$": "%24",
    ",": "%2C",
    "/": "%2F",
    "?": "%3F",
    "#": "%23",
    "[": "%5B",
    "]": "%5D"
  );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}

[class*="icon--"]{
    &:before {
        
        
    }
}

@mixin iconCode($code) {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    $icon_encode: url-encode($code);
    width: $gutter;
    height: $gutter;
    margin: auto;
    background-image: url("data:image/svg+xml;charset=utf8,#{$icon_encode}");
}

@mixin icon($code: "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M13 12l-.688-4h-.609l-.703 4c-.596.347-1 .984-1 1.723 0 1.104.896 2 2 2s2-.896 2-2c0-.739-.404-1.376-1-1.723zm-1-8c-5.522 0-10 4.477-10 10s4.478 10 10 10 10-4.477 10-10-4.478-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm-2-19.819v-2.181h4v2.181c-1.438-.243-2.592-.238-4 0zm9.179 2.226l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.926-1.5-1.328z'/></svg>", $where:"before") {
    &:#{$where} {
        content: "";
        @include iconCode($code);
        margin-right: $gutter/4;

    }
}

%iconButton {
  width: 35px;
  height: 35px;
  @include flex();
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease;
  transition: transform 0.3s ease, opacity 1s ease .75s;
  background: #fff;
  // background: $color--complement;
  @extend %shadow;
  border:3px solid #fff;
  @include preventTouchDelay;
  position: relative;

  svg {
  display: block;
  // width: 24px;
  height: auto;

      * {
          stroke:#fff;
          stroke:#000;
      }
  }
}

@mixin iconButton($code){
    // width:2rem;
    // height:2rem;
    @extend %iconButton;
    @include icon($code);
    padding: $gutter/1.7;

    &:before {
        margin: auto;
        width: auto;
        height: auto;
    }
}
$color:var(--primary);
$colorHover:#fff;

@mixin svgPath($path){
    @include iconButton("<svg width='24' height='24' fill='#{$color}' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'>#{$path}</svg>")
}

@mixin svgPathStroke($path){
    @include iconButton("<svg width='24' height='24' fill='none' stroke-width='2.5' stroke='#{$color}' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'>#{$path}</svg>")
}

@mixin arrowDown {
    // width: 24px;
    // height: 24px;
    display: block;
    transform: rotate(90deg);
    @include iconCode("<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z'/></svg>")
}


.icon {
    &--recepies {
        @include svgPath("<path d='M6.82 10.57h10.35v2.86H6.82z' class='st0' transform='rotate(-45 11.9999 12.00024142)'/><path d='M10.21 5.56h13.61v2.86H10.21z' class='st0' transform='rotate(-135 17.01184764 6.98829742)'/><path d='M15.37 3.09h8.21v2.86h-8.21z' class='st0' transform='rotate(-135 19.47796833 4.52218885)'/><path d='M20.46.7h2.81v2.86h-2.81z' class='st0' transform='rotate(-135 21.86450347 2.13561572)'/><path d='M.18 15.58h13.61v2.86H.18z' class='st0' transform='rotate(45 6.98844764 17.01171501)'/><path d='M.42 18.05h8.21v2.86H.42z' class='st0' transform='rotate(45 4.52236833 19.47786496)'/><path d='M.73 20.43h2.81v2.86H.73z' class='st0' transform='rotate(45 2.13592418 21.8644998)'/>");
        @include svgPath("<path d='M3.6 2.79l7.9 7.75-1.86 1.86L4.6 7.36C3.55 6.15 3.12 5.01 3.12 3.41v-.42c0-.25.3-.38.48-.2zM14.2 13.34l7 7c.48.48.48 1.27 0 1.76-.48.48-1.27.48-1.76 0l-7-7 1.76-1.76z' class='st2'/><path d='M23.21 5.66c0 .2-.07.4-.23.55l-4.54 4.54-.27.27c-.57.57-1.49.57-2.06 0l-.3-.3L4.83 21.68c-.49.48-1.27.48-1.76 0-.24-.24-.36-.56-.36-.88 0-.32.12-.63.36-.88L14.05 8.95l-.46-.46c-.57-.57-.57-1.49 0-2.06l.27-.27-.01-.01 4.54-4.54c.3-.3.79-.3 1.1 0 .15.15.23.35.23.55 0 .2-.07.4-.23.55L15.41 6.8l.69.69 4.16-4.16c.3-.3.79-.3 1.1 0 .15.15.23.35.23.55 0 .2-.07.4-.23.55L17.2 8.59l.61.61 4.08-4.09c.3-.3.79-.3 1.1 0 .14.15.22.35.22.55z' class='st2'/>");
        
    }

    &--account {
        // @include svgPath("<path d='M4.81 4.12V6.2c0 .26.01.51.04.76.37 3.67 3.44 6.53 7.16 6.53s6.78-2.87 7.16-6.53c.03-.25.04-.5.04-.76V4.12H4.81zM12 10.65c-2.15 0-3.94-1.6-4.29-3.69h8.58c-.35 2.1-2.14 3.69-4.29 3.69zM19.19 23.81h-2.84v-2.08c0-2.45-1.95-4.45-4.35-4.45s-4.35 2-4.35 4.45v2.08H4.81v-2.08c0-4.02 3.23-7.29 7.19-7.29s7.19 3.27 7.19 7.29v2.08z' class='st2'/><circle cx='15.61' cy='1.6' r='1.41' class='st2'/><circle cx='8.41' cy='1.6' r='1.41' class='st2'/>");   
        @include svgPath("<path class='st0' d='M0.3,7.78v3.39c0,0.42,0.02,0.83,0.06,1.23C0.98,18.35,5.96,23.02,12,23.02s11.02-4.66,11.63-10.62c0.04-0.4,0.06-0.82,0.06-1.23V7.78H0.3z M12,18.4c-3.49,0-6.4-2.6-6.98-6h13.95C18.4,15.8,15.49,18.4,12,18.4z'/><circle class='st0' cx='17.87' cy='3.67' r='2.29'/><circle class='st0' cx='6.16' cy='3.67' r='2.29'/>");   
    }

    &--pantry {
        @include svgPath("<path d='M7.25 12.03v1.37c0 .17.01.33.03.5.25 2.41 2.26 4.29 4.7 4.29s4.45-1.88 4.7-4.29c.02-.16.03-.33.03-.5v-1.37H7.25zm4.72 4.29c-1.41 0-2.59-1.05-2.82-2.42h5.63c-.22 1.37-1.4 2.42-2.81 2.42z' class='st2'/><circle cx='14.34' cy='10.38' r='.93' class='st2'/><circle cx='9.62' cy='10.38' r='.93' class='st2'/><path d='M22.09 11.06L11.98.95l-.91.91-9.21 9.21h-.02v11.97h.03v.03H22.1v-.03h.01V11.06h-.02zm-17.9.99l7.79-7.79 7.78 7.78v8.66H4.19v-8.65z' class='st2'/>");   
        @include svgPath("<path d='M5.72 8.35v-.02z' class='st2'/><circle cx='18.09' cy='20.63' r='1.28' class='st2'/><circle cx='11.54' cy='20.63' r='1.28' class='st2'/><path d='M23.32 8.39V5.82H8.17l-.9-3.31-.11-.42H.68v2.57h4.47l1.1 3.72v.02l2.56 8.74.07.27h11.8l.11-.38 2.53-8.62v-.02zm-4.57 6.45h-7.93l-1.9-6.45h11.72l-1.89 6.45z' class='st2'/>");   
    }

    &--philosophy {
        @include svgPath("<path d='M13.02 15.41c2.48-.49 4.36-2.68 4.36-5.3 0-2.98-2.42-5.4-5.4-5.4s-5.4 2.42-5.4 5.4c0 2.62 1.88 4.81 4.36 5.3v3.42H8.1v2.08h2.84v2.56h2.08v-2.56h2.84v-2.08h-2.84v-3.42zm-4.35-5.29c0-1.83 1.49-3.31 3.31-3.31s3.31 1.49 3.31 3.31-1.49 3.31-3.31 3.31-3.31-1.49-3.31-3.31z' class='st2'/><path d='M14.57.53v2.12h3.02l-2.73 2.69c-.82-.49-1.78-.77-2.8-.77-3.03 0-5.49 2.46-5.49 5.49s2.46 5.49 5.49 5.49 5.49-2.46 5.49-5.49c0-1.24-.41-2.39-1.11-3.31l2.65-2.62v3.04h2.12V.53h-6.64zm-2.5 12.9c-1.86 0-3.37-1.51-3.37-3.37s1.51-3.37 3.37-3.37 3.37 1.51 3.37 3.37-1.51 3.37-3.37 3.37z' class='st2'/>");   
        @include svgPathStroke("<path d='M1.59 19.36L8.48 7.88c.26-.44.86-.52 1.23-.17l5.77 5.4c.38.35.33.97-.11 1.25L3.96 21.83c-.33.22-.77.16-1.03-.13l-1.25-1.38c-.24-.27-.27-.66-.09-.96zM10.03 13.45l-2.76-2.77M6.6 15.42l-1.66-1.6M13.37 6.8l.41-3.99c.05-.51.53-.86 1.03-.75l1.73.36c.4.08.68.44.68.84v.5c0 .69.77 1.1 1.34.71l.8-.54c.37-.25.86-.18 1.14.16l.81.97c.34.41.23 1.02-.22 1.29-.68.4-.51 1.42.26 1.58l.49.1c.4.08.68.44.68.84v1.07c0 .54-.49.95-1.02.85l-4.89-.93c-.14-.03-.28-.09-.39-.19l-2.55-2.14c-.21-.16-.33-.44-.3-.73z' class='st4'/>");   
    }

    &--training {
        @include svgPath("<path d='M6.82 10.57h10.35v2.86H6.82z' class='st0' transform='rotate(-45 11.9999 12.00024142)'/><path d='M10.21 5.56h13.61v2.86H10.21z' class='st0' transform='rotate(-135 17.01184764 6.98829742)'/><path d='M15.37 3.09h8.21v2.86h-8.21z' class='st0' transform='rotate(-135 19.47796833 4.52218885)'/><path d='M20.46.7h2.81v2.86h-2.81z' class='st0' transform='rotate(-135 21.86450347 2.13561572)'/><path d='M.18 15.58h13.61v2.86H.18z' class='st0' transform='rotate(45 6.98844764 17.01171501)'/><path d='M.42 18.05h8.21v2.86H.42z' class='st0' transform='rotate(45 4.52236833 19.47786496)'/><path d='M.73 20.43h2.81v2.86H.73z' class='st0' transform='rotate(45 2.13592418 21.8644998)'/>");
    }
}

