@import '../utils/mixins';
@import '../utils/icons';
@import '../../../styles/utils/colors';
@import '../../../styles/utils/typography';

%btn {
    background: var(--primary);
    background-color: $primary;
    
    border: 1px solid var(--primary);
    border: 1px solid $primary;
    padding: var(--paddingSmall) var(--padding);
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(#000, .0);
    transition: all .3s ease;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    outline: none;
    @include preventTouchDelay;
    color:#fff;
    position: relative;
    overflow: hidden;
    @include flex();

    + .button, + .btn {
        margin-left: calc(var(--padding) / 4);
    }

    &:hover {
        box-shadow: 0 5px 10px -5px rgba(#000, .2);
        transform: translateY(-2px);
        background-color: var(--btnHover);

    }
    &:focus, &:active {
        box-shadow: 0 0px 5px 0px rgba(#000, .2);
        // box-shadow: 0 0 0 0 rgba(#000, .0);

    }

    &[disabled]{
        pointer-events: none;
    }

    &[data-items]{

        &:before {
            content: attr(data-items);
            width: 20px;
            height: 20px;
            @include flex;
            border-radius: 50%;
            background-color: #fff;
            color: #000;
            font-size: 10px;
            top: 0;
            right: 0;
            position: absolute;
            transform: translate(15%, -15%) scale(1);
            z-index: 1;
            border:2px solid var(--primary);
            opacity: 1;
            transition: var(--bezier);
            background-color: var(--complement);
            background-color: $complement;
            color:#fff;
            border:none;
            padding: 2px;
            // @include fontTitle();
            @include fontNormal($lh:1.1, $fw:700);

        }
    }
    &[data-items="0"]{
        &:before {
            transform: scale(.5);
            opacity: 0;
        }
    }
}

.button, .btn {
    @extend %btn;

    &--small {
        padding: var(--paddingMini) var(--paddingSmall);
    }
    &--large {
        padding: var(--paddingSmall) var(--paddingLarge);
        font-size: 1.2rem;
    }
}

.buttonCircle {
    @extend %btn;
    width: var(--paddingLarge);
    height: var(--paddingLarge);
    @include flex($dir:column);
    border-radius: 50%;
    position: relative;
    overflow: visible;

    i {
        @include flex;
        @include absFill;

        margin: 16%;

        svg {
            fill: #fff;
            width: 100%;
            height: 100%;
        }
    }

    &:hover {
        transform: none;
    }
}

.input, .input-wrapper input {
    border: 1px solid;
    
    padding: var(--paddingSmall);
    outline: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    -webkit-appearance: none;
    @media screen and (max-width:$landscape){
        font-size: 16px;
    }

    &--small {
        padding: var(--paddingMini);
    }


    &[type="number"]{
        max-width: 60px;
        padding-left: 5px;
        padding-right: 5px;
        -webkit-appearance: initial;
        text-align: center;
    }

}

%inputWrapper {
    position: relative;

    input {
        border-radius: 5px;
        border: 2px solid;

        @media screen and (max-width:$landscape){
            font-size: 16px;
        }
    }

    svg {
        position: absolute;
        right: 0;
        top:0;
        bottom:0;
        margin: auto var(--paddingSmall);
        pointer-events: none;
        width: 20px;
        height: 20px;
        fill: $primary;
    }
}

.input-wrapper {
    @extend %inputWrapper;
}

.input-wrapper--small {
    @extend %inputWrapper;

    input {
        padding: var(--paddingMini);
    }

    svg {
        margin: auto var(--paddingMini);
        width: 16px;
        height: 16px;
    }
}

.confirmation-message {
    @include absFill;
    @include flex();
    position: absolute;
    z-index: 1;
    background-color: var(--primary);
    color: var(--pale-silver);
    transform: translateY(100%);
    transition: var(--bezier);

    svg {
        fill: var(--pale-silver);
        margin-right: var(--paddingSmall);
    }

    &--show {
        @extend .confirmation-message;
        transform: none;
    }
}

.inputWithButton {
    @include flex($alignY: stretch, $wrap:nowrap);
    // border-color: var(--primary);
    position: relative;
    overflow: hidden;

    .input, .button {
        border-color: inherit;
        border-width: 2px;
    }
    .input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .button {
        background-color: #4c717f;
        background: var(--primary);
        background: $primary;
        margin: 0;;
        border-color: var(--primary);
        border-color: $primary;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &[disabled]{
            opacity: 0.75;
            // filter: blur(1px);
        }
        

        &:hover {
            transform: none;
            // background-color: darken(#4c717f ,10%);
        }
    }

}

.outline {
    background: none;
    border: 2px solid $primary;
    color: $primary;

    &:hover {
        background: inherit;
        color: var(--btnBgHover);
    }
}

.rounded {
    border-radius: 25px;
}

.inline {
    display: inline-flex;
}