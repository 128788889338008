
//Breakpoints
$portrait:768px;
$landscape:1024px;
$desktop:1100px;
$xl:1350px;
$xxl:1500px;

:root {
    --padding: 25px;
    --paddingMini: 9px;
    --paddingSmall: 15px;
    --paddingMedium: 30px;
    --paddingLarge: 50px;
    --cubicBezier: cubic-bezier(0.465, 0.183, 0.153, 0.946);
    --bezier: all .5s var(--cubicBezier);
}