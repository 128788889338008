@import '../ui/utils/mixins';
@import '../../styles/utils/colors';

.promo {
    // padding: var(--paddingMedium);
    background-color: $primary;
    color: #fff;

    // [class*="columns"]{
        margin-bottom: 0;
    // }

    *:last-child {
        margin-bottom: 0;
    }
    

    &:nth-of-type(2n+3) {
        
    }

    &Content {
        @include flex($dir: column);
        align-self: stretch;
        min-height: 200px;

        a {
            color: inherit;
            @include flex($dir: column);
            text-align: center;
            
            *:only-child {
                font-family: var(--fontNormal);
                text-transform: uppercase;

                &:after {
                    content: "";
                    height: 3px;
                    width: 100%;
                    background: #fff;
                    margin-top: var(--paddingSmallest);
                    display: block;
                }
            }
        }
        
    }
    
    &SingleContent {
        padding: var(--paddingLarge) 0;
        position: relative;

    
        @include mqFrom($portrait){
            max-width: 55%;
        }
    }
}

.badge {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.3;
    object-fit: contain;
    width: 150px;
    height: 150px;

    @include mqFrom($portrait){
        transform: translate(calc(100% + 10vw), 25%);
        opacity: 1;
        width: 250px;
        height: 250px;

    }

}

.reversed {
    background: $beige;
    color: $primary;
    flex-direction: row-reverse;
    
    a {  
        *:only-child {
            &:after {
                background: $primary;
            }
        }
    }
}

.mediaWrapper {
    overflow: hidden;
    margin-bottom: 0;
    width: 100%;

     img, video {
        // max-height: 250px;
        height: auto;
        // height: 300px;
        width: auto;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        object-fit: cover;
        margin: 0;
        vertical-align: bottom;
        max-height: 600px;
    }
}